@import "./common.scss";

.dashboard_main_page {
  padding-bottom: 100px;

  .main_card_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 575px) {
      column-gap: 15px;
      row-gap: 25px;
      grid-template-columns: 1fr;
    }

    > div.dashboard_card {
      // max-width: 204px;
      min-width: 11%;
      flex: 1;

      // &:last-child {
      //   max-width: 156px;
      // }

      @media (max-width: 1199px) {
        min-width: 13%;
        &:last-child {
          max-width: unset;
        }
      }
      @media (max-width: 991px) {
        min-width: 15%;
      }
      @media (max-width: 767px) {
        min-width: 21%;
      }
      @media (max-width: 575px) {
        min-width: 23%;
      }
      @media (max-width: 400px) {
        min-width: unset;
        max-width: unset;
        flex: unset;
      }
    }
  }
}

@import "./common.scss";

.table_data {
  overflow-x: auto;
  width: 100%;

  > table.list_table {
    width: 100%;
    border: none !important;
    color: $txt_color;

    > thead > tr,
    > tbody > tr {
      height: 50px;

      > th {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        text-align: left;
        padding: 0 10px;
        white-space: nowrap;
      }
    }

    > tbody > tr {
      background: linear-gradient(
        116.1deg,
        rgba(255, 255, 255, 0.4) -106.73%,
        rgba(255, 255, 255, 0) 108.62%
      );
      border-radius: 2px;

      > td {
        border: none;
        padding: 10px;
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: $txt_color;

        &:last-child {
          border-left: 1px solid $txt_color;
        }

        > p {
          white-space: nowrap;
          width: fit-content;
        }

        > div {
          display: flex;
          align-items: center;
          width: fit-content;
          white-space: nowrap;

          > button {
            background-color: transparent;
            color: $txt_color;
            border: none;
            outline: none;
            font-size: 18px;
            cursor: pointer;
            padding: 10px;

            &.attendance_btn {
              border: 1px solid $txt_color;
              font-family: $monserrat;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 120%;
              padding: 8px;
              border-radius: 4px;
            }

            &:not(:last-child) {
              margin-right: 25px;
            }

            &:hover {
              // opacity: 0.7;
              background: #fff;
              color: #000;
             
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

@import "./common.scss";

.all_students_main {
  padding-bottom: 100px;

  .all_batch_list {
    background-color: $service_card_bg;
    padding: 30px;
    border-radius: 16px;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    @media screen and (max-width: 575px) {
      background-color: transparent;
      padding: 0;
    }

    > .batch_listHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      > .myInput {
        width: 400px;
        margin-bottom: 0;

        @media screen and (max-width: 575px) {
          width: 300px;
        }
        @media screen and (max-width: 390px) {
          width: auto;
        }
      }

      > .add_batch_button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          116.1deg,
          rgba(118, 118, 121, 0.4) -106.73%,
          rgba(255, 255, 255, 0) 108.62%
        );
        border-radius: 2px;
        width: 40px;
        height: 40px;
        font-size: 16px;
        color: $txt_color;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: $txt_color;
          color: $service_card_bg;
        }
      }
    }
  }
}




// REACT PAGINATION CSS START
// REACT PAGINATION CSS START

// REACT PAGINATION CSS ENDS
// REACT PAGINATION CSS ENDS
@import "./common.scss";

.langSwitch_main {
  position: relative;
  width: 100px;
  height: 30px;
  border-radius: 25px;

  input {
    appearance: none;
    width: 100px;
    height: 30px;
    border-radius: 25px;
    background: #1e1e1e;
    outline: none;
    cursor: pointer;
  }

  input::before,
  input::after {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bolder;
  }

  input:checked {
    background: $service_card_bg;
    border: 1px solid #fff;
  }

  label {
    z-index: 1;
    position: absolute;
    top: 4px;
    bottom: 4px;
    border-radius: 25px;
  }

  input {
    transition: 0.25s;
  }

  input:checked::after,
  input:checked::before {
    color: #fff;
    transition: color 0.5s;
  }

  input:checked + label {
    left: 4px;
    right: 50px;
    background: #1e1e1e;
    border: 1px solid #fff;
    transition: left 0.5s, right 0.4s 0.2s;
  }

  input:not(:checked) {
    background: $service_card_bg;
    border: 1px solid #fff;
    transition: background 0.4s;
  }

  input:not(:checked):before {
    color: #fff;
    transition: color 0.5s;
  }

  input:not(:checked):after {
    color: #fff;
    transition: color 0.5s 0.2s;
  }

  input:not(:checked) + label {
    left: 50px;
    right: 4px;
    background: #1e1e1e;
    border: 1px solid #fff;
    transition: left 0.4s 0.2s, right 0.5s, background 0.35s;
  }
}

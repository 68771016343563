@import "./common.scss";

.create_videoTesti_main {
  padding-bottom: 100px;

  .create_batch_data {
    background-color: $service_card_bg;
    border-radius: 16px;
    padding: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    > .batch_form {
      label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #a9b5bb;
      }

      input,
      select {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $txt_color;
      }
    }

    > .course_summary {
      label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #a9b5bb;
      }

      input,
      select {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $txt_color;
      }
      .add_Emis {
        > .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        > .emi_data_table_main {
          overflow-x: auto;
          width: 100%;

          > .all_emis_table {
            width: 100%;
            overflow: hidden;
            font-size: 12px;
            margin: 15px 0;
            background-color: $page_bg_color;

            thead {
              > tr {
                height: 30px;
                > th {
                  text-align: left;
                  background-color: red;
                  padding: 7px;

                  > p {
                    white-space: nowrap;
                  }
                }
              }
            }
            tbody {
              > tr {
                height: 30px;
                > td {
                  text-align: left;
                  padding: 7px;

                  > p {
                    white-space: nowrap;
                  }

                  > button {
                    background-color: #fff;
                    border: none;
                    padding: 5px;
                    border-radius: 5px;
                    color: $service_card_bg;
                    cursor: pointer;
                    display: block;
                    margin: 0 auto;
                  }
                }
              }
            }
          }
        }

        > .options_data {
          width: 100%;

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            column-gap: 10px;

            .myInput {
              width: 50%;
              margin-bottom: 10px;
            }
          }

          > .add_Emi_btn {
            background-color: #fff;
            color: $service_card_bg;
            border-radius: 7px;
            border: 1px solid #fff;
            padding: 7px 15px;
            cursor: pointer;
            margin: 0 auto;
            display: block;

            &:hover {
              background-color: $page_bg_color;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.create_course_btn {
  margin: 40px auto 0;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  width: auto;
}

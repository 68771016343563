@import "./common.scss";

.update_textTesti_main {
  padding-bottom: 100px;

  .sub_title_main {
    font-family: $monserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #a9b5bb;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      border-radius: 8px;
      outline: none;
      color: $txt_color;
      background-color: $service_card_bg;
      border: 1px solid $txt_color;
      font-family: $monserrat;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 103px;
      height: 30px;
      cursor: pointer;
      transition: 0.4s;

      > svg {
        margin-right: 5px;
        font-size: 18px;
      }

      &:hover {
        color: $service_card_bg;
        background-color: $txt_color;
        border: 1px solid $service_card_bg;
      }
      &.activeBtn {
        color: $txt_color;
        background-color: red;
        border: 1px solid $txt_color;
      }
    }
  }

  .active_teacher {
    display: flex;
    align-items: center;

    > .langSwitch_main {
      margin-left: 15px;
    }
  }

  .update_textTesti_data {
    background-color: $service_card_bg;
    border-radius: 16px;
    padding: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    > .batch_form {
      label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #a9b5bb;
      }

      input,
      select {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $txt_color;
      }
    }

    > .course_summary {
      label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #a9b5bb;
      }

      input,
      select {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $txt_color;
      }
    }
  }
  .create_course_btn {
    margin: 40px auto 0;
    padding-right: 15px;
    padding-left: 15px;
    width: auto;
  }
}

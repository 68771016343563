// Variables
$blue: #26bbf0;
$green: #c2d5a0;
$red: #cf6a6d;
$yellow: #ffbc0d;

$black: #343536;
$black-2: #424950;
$grey: #89949b;
$grey-2: #9aa8b1;
$light-grey: #c4c9cd;
$light-grey-2: #dbdfe1;
$white: #f3f4f5;

@import "./common.scss";

// Styles
body {
  // -webkit-font-smoothing: antialiased;
  // text-rendering: optimizeLegibility;
  // font-family: "proxima-nova-soft", sans-serif;
  // -webkit-user-select: none;
  // overflow: hidden;

  .vertical-centered-box {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;

    &:after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }

    .content {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      font-size: 0;
    }
  }
}

// Additional Styles
body {
  background: mix(#121d40, #081336, 70%);
}

// #prism-logo {
//   path {
//     fill: #20293b;
//     stroke: rgba(255,255,255,1);
//     stroke-width: 1px;
//   }
// }

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  margin-left: -60px;
  margin-top: -60px;
  // .animation(fade 1.2s infinite ease-in-out);
}
.content > img {
  width: 70px;
}
.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  animation: rotate 1.2s infinite linear;

  .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

#particles-background,
#particles-foreground {
  left: -51%;
  top: -51%;
  width: 202%;
  height: 202%;
  transform: scale3d(0.5, 0.5, 1);
}

#particles-background {
  background: mix(#3f3251, #002025, 70%);
  background-image: -moz-linear-gradient(45deg, #3f3251 2%, #002025 100%);
  background-image: -webkit-linear-gradient(45deg, #3f3251 2%, #002025 100%);
  background-image: linear-gradient(45deg, #3f3251 2%, #002025 100%);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@import "./common.scss";

.login_main_sec {
  height: 100vh;
  width: 100%;

  > .login_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > form.login_form {
      width: 450px;
      padding: 0 15px;

      > .login_logo {
        width: 188px;
        height: 50px;
        display: block;
        margin: 0 auto 25px;
      }

      > .loginButton {
        margin: 0 auto;
        font-size: 16px;

        &:hover {
          font-weight: 700;
          letter-spacing: 3px;
        }
      }

      > .forgot_password {
        text-align: center;
        margin-top: 25px;

        > a {
          color: $txt_color;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

@import "./common.scss";

.create_video_main {
  padding-bottom: 100px;

  .create_video_data {
    background-color: $service_card_bg;
    border-radius: 16px;
    padding: 30px;
    box-sizing: border-box;
    @media screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    > .video_form {
      label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #a9b5bb;
      }

      input {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $txt_color;
      }
      .add_tags {
        margin-top: 30px;
        > .tags {
          display: flex;
          align-items: center;
          column-gap: 10px;
          width: 100%;
          > .myInput {
            width: calc(100% - 50px);
          }
          > button {
            cursor: pointer;
            height: 40px;
            width: 40px;
            font-size: 16px;
            color: $page_bg_color;
            background-color: $txt_color;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $txt_color;
          }
        }
        > .tag_list {
          > p {
            font-size: 16px;
            margin-bottom: 15px;
            color: $txt_color;
            font-family: $monserrat;
          }
          > ul {
            list-style: none;
            color: $txt_color;
            display: flex;
            column-gap: 10px;
            row-gap: 10px;
            flex-wrap: wrap;
            > li {
              display: flex;
              align-items: center;
              column-gap: 10px;
              background-color: $txt_color;
              color: $page_bg_color;
              padding: 3px 8px;
              border-radius: 8px;
              font-size: 14px;
              > button {
                cursor: pointer;
                font-size: 16px;
                color: $page_bg_color;
                padding: 0;
                outline: none;
                box-shadow: none;
                background-color: transparent;
                border: none;
                line-height: 0;
              }
            }
          }
        }
      }
    }

    > .create_btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0px;
      @media (max-width: 567px) {
        flex-direction: column;
        justify-content: center;
        row-gap: 20px;
      }
      .create_course_btn {
        padding-right: 15px;
        padding-left: 15px;
        width: auto;
      }
    }
  }
}

@import "./common.scss";

.header_main {
  // overflow-x: hidden;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // background: #081336;
  // z-index: 2;

  .nav_bar_sec {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .logo {
      > a {
        display: flex;
        align-items: center;
        > img {
          &.desktop {
            width: 188px;
            color: $txt_color;
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
          &.mobile {
            display: none;
            @media screen and (max-width: 767px) {
              display: block;
              width: 40px;
            }
            @media screen and (max-width: 575px) {
              width: 32px;
            }
          }
        }
        > p {
          font-family: $casanova;
          font-size: 18px;
          color: $txt_color;
          margin: 0;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }
    > .open_navbar_btn {
      color: $txt_color;
      font-size: 30px;
      display: none;
      @media screen and (max-width: 991px) {
        display: block;
      }
      > svg {
        cursor: pointer;
      }
    }
    > .nav_links {
      display: flex;
      align-items: center;
      font-family: $monserrat;
      z-index: 1;

      @media screen and (max-width: 991px) {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        background-color: $txt_color;
        width: 100%;
        height: 100vh;
        transform: translateX(100%);
        transition: 0.4s;
        padding-top: 50px;
        box-sizing: border-box;
        overflow-y: auto;
        background-color: $service_card_bg;
        // border-radius: 30px 0 0 30px;
        // border-left: 1px solid $txt_color;
      }

      > .close_menu_btn {
        position: absolute;
        top: 15px;
        right: 15px;
        color: $txt_color;
        font-size: 20px;
        > svg {
          cursor: pointer;
        }
        @media screen and (min-width: 991px) {
          display: none;
        }
      }

      // ===============================
      > .nav_link {
        &:not(:last-child) {
          margin-right: 35px;
        }
        @media screen and (max-width: 991px) {
          margin: 0 20px 30px 20px !important;
        }

        > .myInput {
          margin: 0;

          input {
            width: 400px;

            @media screen and (max-width: 991px) {
              width: auto;
            }
          }
        }

        > .notification_bell {
          position: relative;
          cursor: pointer;
          width: fit-content;

          > svg {
            font-size: 23px;
            > path {
              stroke: $txt_color;
            }
          }

          > span {
            position: absolute;
            top: -2px;
            right: -4px;
            width: 13px;
            height: 13px;
            border: 1px solid #f2f2f2;
            border-radius: 100%;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $page_bg_color;
          }
        }

        > .userDetails {
          display: flex;
          align-items: center;
          cursor: pointer;

          > span {
            &:first-child {
              font-size: 18px;
              margin-right: 5px;
            }

            &:last-child {
              > img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                border: 1px solid $txt_color;
              }
            }
          }
        }
      }
      // ===============================
    }

    > .open_navbar {
      transform: translateX(0);
      z-index: 100;
    }
  }
}

/* FONT FACE IMPORT THROUGH CSS */
@font-face {
  font-family: "casanova";
  src: local("casanova"),
    url("../font/casanova-scotia-font/CasanovaScotia-Xm0K.ttf")
      format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../font/Montserrat/Montserrat-VariableFont_wght.ttf")
      format("truetype");
}

// FONT VARS
$monserrat: "Montserrat", sans-serif;
$casanova: "casanova", sans-serif;

// COLORS VARS
$page_bg_color: #081336;
$txt_color: #f2f2f2;
$linear_border: #96abe3;
$service_card_bg: #121d40;

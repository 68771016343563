/* FONT FACE IMPORT THROUGH CSS */
@font-face {
  font-family: "casanova";
  src: local("casanova"),
    url("./font/casanova-scotia-font/CasanovaScotia-Xm0K.ttf")
      format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./font/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
}

.casanova_font {
  font-weight: normal;
  font-family: "casanova", sans-serif;
}

.monserrat_font {
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
}

body {
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}

.bg_color {
  background-color: #081336;
}

.txt_color {
  color: #f2f2f2;
}

.txt_center {
  text-align: center;
}

.disabled {
  opacity: 0.6;
  pointer-events: none !important;
}

.alertText_style {
  width: fit-content;
  display: block;
  margin: 0 auto;
  font-weight: 500;
  color: #081336;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 4px;
}

/* remove number symbol from input */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for firefox */
input[type="number"] {
  -webkit-appearance: textfield;
}

/* INPUT TYPE DATE ICON CSS START */
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

/* INPUT TYPE DATE ICON CSS ENDS */

/* CONTAINER LAYOUT STYLE START */
@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 100%;
    margin: 0 15px;
  }
}

/* CONTAINER LAYOUT STYLE ENDS */

/* SCROLLBAR CSS START */
/* width */
.custonScroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custonScroll::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

/* Handle */
.custonScroll::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 10px;
}

/* Handle on hover */
.custonScroll::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
}

/* SCROLLBAR CSS ENDS */

/* CUSTOM KEYFRAMES START */

/* KEYFRAME OF LOADER ANIMATION START */
@keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* CUSTOM KEYFRAMES ENDS */

@import "./common.scss";

.create_blog_main {
  padding-bottom: 100px;

  .create_blog_data {
    background-color: $service_card_bg;
    border-radius: 16px;
    padding: 30px;
    box-sizing: border-box;
    @media screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    > .batch_form {
      label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #a9b5bb;
      }

      input,
      select {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: $txt_color;
      }

      .blog_data {
        height: 480px;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
        > .quill {
          .ql-toolbar {
            .ql-formats {
              .ql-header {
                color: #fff;
              }
              > .ql-bold {
                color: #fff;
                &.ql-active {
                  border: 1px solid #fff;
                  color: #fff;
                }
              }
              > .ql-italic {
                color: #fff;
                &.ql-active {
                  border: 1px solid #fff;
                  color: #fff;
                }
              }
              > .ql-underline {
                color: #fff;
                &.ql-active {
                  border: 1px solid #fff;
                  color: #fff;
                }
              }
              > .ql-strike {
                color: #fff;
                &.ql-active {
                  border: 1px solid #fff;
                  color: #fff;
                }
              }
            }
          }
        }
        > p {
          font-family: $monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #a9b5bb;
          margin-bottom: 7px;
        }
      }

      .social_links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        @media (max-width: 767px) {
          grid-template-columns: 1fr;
        }
      }
    }

    > .create_btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0px;
      @media(max-width:567px){
        flex-direction: column;
        justify-content: center;
        row-gap: 20px;
      }
      .create_course_btn {
        padding-right: 15px;
        padding-left: 15px;
        width: auto;
      }
    }
  }
}

@import "./common.scss";

.view_student_main {
  .student_profile {
    display: flex;
    column-gap: 30px;

    > .student_Parent_details_main {
      width: 75%;
      > .title {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #a9b5bb;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > button {
          border-radius: 8px;
          outline: none;
          color: $txt_color;
          background-color: $service_card_bg;
          border: 1px solid $txt_color;
          font-family: $monserrat;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 103px;
          height: 30px;
          cursor: pointer;
          transition: 0.4s;

          > svg {
            margin-right: 5px;
            font-size: 18px;
          }

          &:hover {
            color: $service_card_bg;
            background-color: $txt_color;
            border: 1px solid $service_card_bg;
          }
          &.activeBtn {
            color: $txt_color;
            background-color: red;
            border: 1px solid $txt_color;
          }
        }
      }

      > .details_card {
        background-color: $service_card_bg;
        border-radius: 16px;
        padding: 20px;

        &.studentDetails {
          display: flex;
          column-gap: 20px;

          > div {
            flex: 1;

            .myInput {
              label {
                font-size: 14px;
                color: #a9b5bb;
              }
              input,
              select {
                height: 30px;
                font-size: 11px;
                color: #fff;
                padding: 0 10px;
              }
            }

            &.userImage {
              position: relative;

              > img {
                height: 200px;
                width: 200px;
                object-fit: cover;
                border: 1px solid $txt_color;
                border-radius: 16px;
              }
              > .edit_btn {
                position: absolute;
                top: 10px;
                right: 10px;
              }
            }
          }
        }
      }

      >.update_user_btn{
        margin: 50px auto 0;
      }
    }
    > .performance_stats {
      width: 25%;
      > .title {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #a9b5bb;
        margin-bottom: 15px;
      }
    }
  }
}

@import "./common.scss";

.all_teachers_main {
  padding-bottom: 100px;

  .all_batch_list {
    background-color: $service_card_bg;
    padding: 30px;
    border-radius: 16px;

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    @media screen and (max-width: 575px) {
      background-color: transparent;
      padding: 0;
    }

    > .batch_listHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      > .myInput {
        width: 400px;
        margin-bottom: 0;

        @media screen and (max-width: 575px) {
          width: 300px;
        }
        @media screen and (max-width: 390px) {
          width: auto;
        }
      }

      > .add_batch_button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          116.1deg,
          rgba(118, 118, 121, 0.4) -106.73%,
          rgba(255, 255, 255, 0) 108.62%
        );
        border-radius: 2px;
        width: 40px;
        height: 40px;
        font-size: 16px;
        color: $txt_color;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: $txt_color;
          color: $service_card_bg;
        }
      }
    }

    > .navPanelFilter {
      > .navTabs {
        padding-left: 0px;
        list-style: none;
        display: flex;
        align-items: flex-end;
        column-gap: 10px;
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(229, 229, 229, 0.2117647059);
        width: 100%;
        overflow-x: auto;

        > li {
          cursor: pointer;
          color: #f2f2f2;
          margin-bottom: 0px;
          font-size: 16px;
          padding: 4px 10px;
          white-space: nowrap;
          transition: 0.4s;
          border: 1px solid transparent;
          border-radius: 5px 5px 0px 0px;
          border-bottom: unset;

          &:hover {
            border: 1px solid rgba(229, 229, 229, 0.2117647059);
            border-bottom: unset;
          }

          &.active {
            background-color: #f2f2f2;
            border: 1px solid;
            border-bottom: unset;
            color: #081336;
            border-radius: 5px 5px 0px 0px;
          }
        }
      }
    }
  }
}

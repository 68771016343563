@import "./common.scss";

.pagination_sec {
  margin: 40px auto 0;

  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    > li {
      font-size: 16px;
      padding: 5px;
      border: 1px solid $txt_color;
      min-width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.selected {
        background-color: $txt_color;
        color: $page_bg_color;
      }
    }
  }
}

@import "./common.scss";

.create_batch_main {
  padding-bottom: 100px;

  .create_batch_data {
    background-color: $service_card_bg;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 16px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    @media screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    > div {
      display: flex;
      column-gap: 30px;

      > .batch_form {
        width: 40%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        label {
          font-family: $monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #a9b5bb;
        }

        input,
        select {
          font-family: $monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 120%;
          color: $txt_color;
        }
      }

      > .batch_timeTable {
        width: 60%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        > .title_label {
          font-family: $monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #a9b5bb;
          margin-bottom: 7px;
        }

        > div {
          display: flex;
          align-items: center;
          row-gap: 20px;
          column-gap: 20px;
          flex-wrap: wrap;

          .batch_timeCard {
            background: linear-gradient(
              116.1deg,
              rgba(118, 118, 121, 0.4) -106.73%,
              rgba(255, 255, 255, 0) 108.62%
            );
            backdrop-filter: blur(50px);
            border-radius: 4px;
            // padding: 15px;
            width: 150px;
            // height: 80px;

            @media screen and (max-width: 390px) {
              width: 120px;
            }

            > div {
              // height: 40px;
              padding: 10px 0;
            }

            > .weekDay {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              border-bottom: 1px solid #f2f2f2;

              > span {
                &:first-child {
                  font-family: $monserrat;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 11px;
                  line-height: 120%;
                  color: $txt_color;
                }

                &:last-child {
                  background: linear-gradient(
                    116.1deg,
                    rgba(118, 118, 121, 0.4) -106.73%,
                    rgba(255, 255, 255, 0) 108.62%
                  );
                  border-radius: 4px;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 15px;
                  cursor: pointer;

                  &:hover {
                    background-color: $txt_color;
                    color: $service_card_bg;
                  }
                }
              }
            }

            > .batch_Time {
              display: flex;
              align-items: center;
              flex-direction: column;

              > span {
                font-family: $monserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 120%;
                color: $txt_color;
                margin-top: 7px;
              }
            }
          }
        }

        > .add_timeTable {
          display: flex;
          margin-bottom: 20px;

          > div {
            > .timetable_tile {
              margin-right: 30px;
              width: 200px;
              // height: 80px;
              background: linear-gradient(
                116.1deg,
                rgba(118, 118, 121, 0.4) -106.73%,
                rgba(255, 255, 255, 0) 108.62%
              );
              -webkit-backdrop-filter: blur(50px);
              backdrop-filter: blur(50px);
              border-radius: 4px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              > span {
                padding: 8px;
                box-sizing: border-box;
                width: 100%;

                &:first-child {
                  border-bottom: 1px solid $txt_color;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  > select {
                    outline: none;
                    border: none;
                    background-color: transparent;
                    color: $txt_color;
                    cursor: pointer;

                    option {
                      background-color: $page_bg_color;
                      color: $txt_color;
                    }
                  }

                  > button {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $txt_color;
                    background-color: $txt_color;
                    color: red;
                    cursor: pointer;
                    border-radius: 4px;

                    &:hover {
                      opacity: 0.8;
                    }
                  }
                }

                &:last-child {
                  > input {
                    background-color: transparent;
                    color: $txt_color;
                    border: none;
                    outline: none;
                    width: 100%;
                    &::-webkit-calendar-picker-indicator {
                      background-color: #fff;
                      cursor: pointer;
                      color: $txt_color;
                    }
                  }

                  > .time_list {
                    margin-top: 20px;

                    > h5 {
                      background-color: $txt_color;
                      color: $page_bg_color;
                      text-align: center;
                      padding: 5px;
                      text-transform: uppercase;
                    }

                    > p {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      font-size: 14px;
                      margin: 5px 0;

                      > span {
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        background-color: $txt_color;
                        color: $page_bg_color;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }
                  }
                }
              }
            }
          }
          .btn_sec {
            .add_time_btn {
              width: fit-content;
              height: auto;
              padding: 7px 15px;
              font-size: 14px;
            }
          }
        }
      }
    }

    > .create_batch_btn {
      margin: 30px auto 0;
    }
  }
}

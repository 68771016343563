@import "./common.scss";

.create_Teacher_main {
  .student_profile {
    display: flex;
    column-gap: 30px;

    > .student_Parent_details_main {
      width: 100%;
      background-color: $service_card_bg;
      border-radius: 16px;
      padding: 20px;

      > .title {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #a9b5bb;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > button {
          border-radius: 8px;
          outline: none;
          color: $txt_color;
          background-color: $service_card_bg;
          border: 1px solid $txt_color;
          font-family: $monserrat;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 103px;
          height: 30px;
          cursor: pointer;
          transition: 0.4s;

          > svg {
            margin-right: 5px;
            font-size: 18px;
          }

          &:hover {
            color: $service_card_bg;
            background-color: $txt_color;
            border: 1px solid $service_card_bg;
          }
          &.activeBtn {
            color: $txt_color;
            background-color: red;
            border: 1px solid $txt_color;
          }
        }
      }

      >.active_teacher{
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        >.langSwitch_main{
          margin-left: 15px;
        }
      }

      > .details_card {
        // background-color: $service_card_bg;
        // border-radius: 16px;
        // padding: 20px;

        &.studentDetails {
          display: flex;
          column-gap: 20px;

          > div {
            flex: 1;

            .myInput {
              label {
                font-size: 14px;
                color: #a9b5bb;
              }
              input,
              select {
                // height: 30px;
                // font-size: 11px;
                color: #fff;
                // padding: 0 10px;
              }
            }

            &.userImage {
              position: relative;

              > img {
                height: 200px;
                width: 200px;
                object-fit: cover;
                border: 1px solid $txt_color;
                border-radius: 16px;
              }
              > .edit_btn {
                position: absolute;
                top: 10px;
                right: 10px;
              }
            }
          }
        }
      }

      > .add_time_title {
        display: block;
        width: 100%;
        margin-bottom: 7px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgb(169, 181, 187);
        @media screen and (max-width: 575px) {
          font-size: 13px;
        }
      }

      > .add_timeTable {
        display: flex;

        > div {
          > .timetable_tile {
            margin-right: 30px;
            width: 200px;
            // height: 80px;
            background: linear-gradient(
              116.1deg,
              rgba(118, 118, 121, 0.4) -106.73%,
              rgba(255, 255, 255, 0) 108.62%
            );
            -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
            border-radius: 4px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            > span {
              padding: 8px;
              box-sizing: border-box;
              width: 100%;

              &:first-child {
                border-bottom: 1px solid $txt_color;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > select {
                  outline: none;
                  border: none;
                  background-color: transparent;
                  color: $txt_color;
                  cursor: pointer;

                  option {
                    background-color: $page_bg_color;
                    color: $txt_color;
                  }
                }

                > button {
                  width: 30px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid $txt_color;
                  background-color: $txt_color;
                  color: red;
                  cursor: pointer;
                  border-radius: 4px;

                  &:hover {
                    opacity: 0.8;
                  }
                }
              }

              &:last-child {
                > input {
                  background-color: transparent;
                  color: $txt_color;
                  border: none;
                  outline: none;
                  width: 100%;
                  &::-webkit-calendar-picker-indicator {
                    background-color: #fff;
                    cursor: pointer;
                    color: $txt_color;
                  }
                }

                > .time_list {
                  margin-top: 20px;

                  > h5 {
                    background-color: $txt_color;
                    color: $page_bg_color;
                    text-align: center;
                    padding: 5px;
                    text-transform: uppercase;
                  }

                  > p {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    margin: 5px 0;

                    > span {
                      cursor: pointer;
                      width: 20px;
                      height: 20px;
                      background-color: $txt_color;
                      color: $page_bg_color;
                      border-radius: 4px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }
        .btn_sec {
          .add_time_btn {
            width: fit-content;
            height: auto;
            padding: 7px 15px;
            font-size: 14px;
          }
        }
      }

      > .main_timeTable_list {
        margin-top: 40px;
        border: 1px solid $txt_color;

        > h5 {
          text-align: center;
          color: $page_bg_color;
          background-color: $txt_color;
          padding: 7px;
          font-size: 15px;
        }
      }

      > .update_user_btn {
        margin: 50px auto;
      }
    }
  }
}

@import "./common.scss";

.create_batch_main {
  padding-bottom: 100px;

  .create_batch_data {
    background-color: $service_card_bg;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 16px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    @media screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    > div {
      > .batch_form {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        row-gap: 30px;
        @media screen and (max-width: 991px) {
          grid-template-columns: 1fr;
        }

        label {
          font-family: $monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #a9b5bb;
        }

        input,
        select {
          font-family: $monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 120%;
          color: $txt_color;
        }
      }
    }

    > .create_batch_btn {
      margin: 30px auto 0;
    }
  }
}

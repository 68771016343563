@import "./common.scss";

.affiliate_profile_section {
  > .profilePage_heading {
    font-family: $monserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 120%;
    letter-spacing: 0.1em;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  > .user_details_main {
    margin-top: 30px;
    > .user_pro_data {
      > h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #a9b5bb;

        > button {
          border-radius: 8px;
          outline: none;
          color: $txt_color;
          background-color: $service_card_bg;
          border: 1px solid $txt_color;
          font-family: $monserrat;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 103px;
          height: 30px;
          cursor: pointer;
          transition: 0.4s;

          > svg {
            margin-right: 5px;
            font-size: 18px;
          }

          &:hover {
            color: $service_card_bg;
            background-color: $txt_color;
            border: 1px solid $service_card_bg;
          }
          &.activeBtn {
            color: $txt_color;
            background-color: red;
            border: 1px solid $txt_color;
          }
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        @media screen and (max-width: 575px) {
          flex-direction: column;
        }

        > .data_card {
          width: calc(100% - 300px);
          background-color: $service_card_bg;
          border-radius: 16px;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          column-gap: 20px;
          flex-wrap: wrap;
          align-items: center;

          > .pro_update_btn {
            margin: 0 auto;
            font-size: 14px;
            height: 35px;
          }

          @media screen and (max-width: 1050px) {
            width: calc(100% - 200px);
          }
          @media screen and (max-width: 575px) {
            width: 100%;
            order: 2;
          }

          > .myInput {
            width: 48%;

            @media screen and (max-width: 1170px) {
              width: 47%;
            }
            @media screen and (max-width: 605px) {
              width: 100%;
            }

            label {
              font-size: 14px;
            }
            input,
            select {
              height: 30px;
              font-size: 11px;
              padding: 0 10px;

              @media screen and (max-width: 575px) {
                padding: 9px 10px;
              }
            }
          }
        }

        > .profile_picSec {
          @media screen and (max-width: 575px) {
            order: 1;
            margin-bottom: 20px;
          }
          .user_pro_pic {
            position: relative;

            @media screen and (max-width: 575px) {
              display: flex;
              align-items: center;
              justify-content: center;
              position: unset;
            }

            > img {
              width: 240px;
              height: 240px;
              border-radius: 11.76px;
              background-color: $service_card_bg;
              object-fit: cover;

              @media screen and (max-width: 1050px) {
                width: 170px;
                height: 170px;
              }
              @media screen and (max-width: 575px) {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                margin: 0 10px;
              }
            }

            > .absolute_btn {
              position: absolute;
              top: 0;
              right: 0;
              width: 30px;
              height: 30px;
              background-color: $service_card_bg;
              border: 1px solid $txt_color;
              border-radius: 0 11.76px 0 11.76px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              color: $txt_color;
              cursor: pointer;

              @media screen and (max-width: 575px) {
                position: unset;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                font-size: 20px;
              }

              &:hover {
                background-color: $txt_color;
                color: $service_card_bg;
              }
            }

            > .deleteImage_btn {
              left: 0;
              z-index: 1;
              border-radius: 11.76px 0 11.76px 0;

              @media screen and (max-width: 575px) {
                border-radius: 50%;
              }

              &:hover {
                background-color: $txt_color;
                color: red;
              }
            }
          }
        }
      }
    }
  }

  .create_affilaite_btn {
    margin: 50px auto;
    width: auto;
    padding: 10px 20px;
  }
}

.upload_profile_pic_main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $page_bg_color;
  z-index: 99;
}
